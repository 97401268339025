import { Component, OnInit,Inject,Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from "./../translate.service";
import {ApiModel} from "../classes/api_model";
import {swaggerApiService} from "../services/swaggerApi.service";
import {JSGenerateRSAKey} from "../services/JSGenerateRSAKey";
import {MatSnackBar} from "@angular/material/snack-bar";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  videoList = [];
  cateVideoList = [];
  isLoading = false;
  panelOpenState: boolean = false;
  @Input() currentLanguage: string;
  key:JSGenerateRSAKey;

  constructor(private db: AngularFirestore, public dialog: MatDialog, private router: Router,private translate: TranslateService,private _swaggerApiService:swaggerApiService,private _snackBar:MatSnackBar) {

  }
  
  public navigateToDetail(news) {
    localStorage.setItem('data', JSON.stringify(news));
    window.open('show-detail', '_blank');
  }
  toKhmerNumber(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g,'០')
    khNumber = khNumber.replace(/1/g,'១')
    khNumber = khNumber.replace(/2/g,'២')
    khNumber = khNumber.replace(/3/g,"៣")
    khNumber = khNumber.replace(/4/g,"៤")
    khNumber = khNumber.replace(/5/g,"៥")
    khNumber = khNumber.replace(/6/g,"៦")
    khNumber = khNumber.replace(/7/g,"៧")
    khNumber = khNumber.replace(/8/g,"៨")
    khNumber = khNumber.replace(/9/g,"៩")
    
    return khNumber
  }
  toKhmerMonth(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/01/g,this.translate.data.MONTH_01)
    khNumber = khNumber.replace(/02/g,this.translate.data.MONTH_02)
    khNumber = khNumber.replace(/03/g,this.translate.data.MONTH_03)
    khNumber = khNumber.replace(/04/g,this.translate.data.MONTH_04)
    khNumber = khNumber.replace(/05/g,this.translate.data.MONTH_05)
    khNumber = khNumber.replace(/06/g,this.translate.data.MONTH_06)
    khNumber = khNumber.replace(/07/g,this.translate.data.MONTH_07)
    khNumber = khNumber.replace(/08/g,this.translate.data.MONTH_08)
    khNumber = khNumber.replace(/09/g,this.translate.data.MONTH_09)
    khNumber = khNumber.replace(/10/g,this.translate.data.MONTH_10)
    khNumber = khNumber.replace(/11/g,this.translate.data.MONTH_11)
    khNumber = khNumber.replace(/12/g,this.translate.data.MONTH_12)
    
    return khNumber
  }
  convertDate(date){
    let dateList,dateStr='';
    let dateLabel=['ថ្ងៃទី','ខែ','ឆ្នាំ'];
    dateList=date.split('-');
    dateList[1]=this.toKhmerMonth(dateList[1]);
    if(this.currentLanguage=='km'){
      for(let i=0;i<dateList.length;i++){
        dateList[i]=this.toKhmerNumber(dateList[i]);
        dateStr+=dateLabel[i]+dateList[i]+' ';
      }
    } else {
      for(let i=0;i<dateList.length;i++){
        dateStr+=dateList[i]+' ';
      }
    }
    return dateStr;
  }
  ngOnInit(){
    this.key=new JSGenerateRSAKey();
    let objCategory= new ApiModel();
    this.isLoading = true;
    objCategory.public_key=this.key.getPublicKey();
    objCategory.cateType='videoType';
    this._swaggerApiService.postCategory(objCategory)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.private_key=this.key.getPrivateKey();
          objDecrypt.encryptedData=data;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                if(data.statusCode==200 && data.item.length>0)
                {
                  data.item.forEach(element => 
                    {
                      this.cateVideoList.push(element);
                      this.isLoading = false;
                    }
                  );
                  // console.info("videoCategory = ",this.cateVideoList);

                }
                else if(data.statusCode==200 && data.item.length<=0)
                {
                  //Toast message to users
                  this._snackBar.open("oops! no more records found.", "OK", {
                    duration: 2000
                  });
                }
                else
                {
                  //Toast message to users
                  this._snackBar.open(data.message, "OK", {
                    duration: 2000
                  });
                }
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                this._snackBar.open("Category Decrypt Res_Err: "+errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("Category Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  eduVideo(event)
  {
    let cate_id=event.tab._viewContainerRef._view.component.cateVideoList[event.index]._id;
    this.loadVideoList(cate_id);
  }
  loadVideoList(category_id) {
    this.videoList = [];
    this.key=new JSGenerateRSAKey();
    let objVideo= new ApiModel();
    objVideo.public_key=this.key.getPublicKey();
    objVideo.modified_date=new Date().toISOString();
    objVideo.category=category_id;
    objVideo.limit=100;

    // console.log('objVideo == ', objVideo);
    
    this._swaggerApiService.postEduvideo(objVideo)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.private_key=this.key.getPrivateKey();
          objDecrypt.encryptedData=data;
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                if(data.statusCode==200 && data.item.length>0)
                {
                  data.item.forEach(element => 
                    {
                      this.videoList.push(element);
                    }
                  );
                  // console.info("videoList = ",this.videoList);
                }
                else if(data.statusCode==200 && data.item.length<=0)
                {
                  //Toast message to users
                  this._snackBar.open("oops! no more records found.", "OK", {
                    duration: 2000
                  });
                }
                else
                {
                  //Toast message to users
                  this._snackBar.open(data.message, "OK", {
                    duration: 2000
                  });
                }
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                this._snackBar.open("E-Video Decrypt Res_Err: "+errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("E-Video Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  findLang(cate){
    if(this.currentLanguage=='km' && (cate.cateNameKh!=null && cate.cateNameKh!=undefined && cate.cateNameKh!='')){
      return cate.cateNameKh;
    } else if (this.currentLanguage=='en' && (cate.cateNameEn!=null && cate.cateNameEn!=undefined && cate.cateNameEn!='')){
      return cate.cateNameEn;

    } else if (this.currentLanguage=='fr' && (cate.cateNameFr!=null && cate.cateNameFr!=undefined && cate.cateNameFr!='')){
      return cate.cateNameFr;
    }
    return cate.cateNameKh;
  }
  openDialog() {
    const dialogRef = this.dialog.open(PlayVideo);

    dialogRef.afterClosed().subscribe(result => {
      console.log("The video was closed");
    });
  }
}
@Component({
  selector: "play-videos",
  templateUrl: "play-videos.html"

})
export class PlayVideo {
  title: string;
  constructor(
    public dialogRef: MatDialogRef<PlayVideo>,
  ) {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
function groupBy(data, property) {
  return data.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}