import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-shimmer-loading',
  templateUrl: './news-shimmer-loading.component.html',
  styleUrls: ['./news-shimmer-loading.component.css']
})
export class NewsShimmerLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
